import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Box, Flex, Text, useToken } from '@chakra-ui/react'
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  Line,
} from 'recharts'
import { format } from 'date-fns'

import { GENERATOR_KEYS, MEASUREMENT_UNITS_MAP } from '../../utils/consts'
import { addCommasToNumber } from '../../utils/helpers'

const CustomDot = (props) => {
  const { cx, cy, value } = props

  if (value <= 0) return null

  return (
    <svg
      x={cx - 5.5}
      y={cy - 5.5}
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5.5"
        cy="5.5"
        r="4"
        fill="white"
        stroke={props.stroke}
        strokeWidth="2"
      />
    </svg>
  )
}

const colorsGeneratorsMap = [
  {
    stroke: '#21D17F',
    stops: {
      50: '#21D17F80',
      80: '#1DABFC4C',
    },
  },
]

const colorsMap = [
  {
    stroke: '#EB6F21',
    stops: {
      30: '#FF715080',
      100: '#1DABFC4D',
    },
  },
  {
    stroke: '#1DABFC',
    stops: { 42: '#1DABFC60', 100: '#1DABFC10' },
  },
]

const getColors = (key, index) => {
  if (GENERATOR_KEYS.some((gk) => gk === key)) {
    return colorsGeneratorsMap[0]
  }

  return colorsMap[index]
}

export const RechartsAreaChart: React.FC<{
  data: any
  dataKeys: { displayValue: string; key: string }[]
  period: string
  title?: string
}> = ({ data, dataKeys, period, title }) => {
  const [textSecondary] = useToken('colors', ['text.secondary'])
  const [currentCoordinates, setCurrentCoordinates] = useState(null)
  const [activePoint, setActivePoint] = useState(null)
  const activeDots = useRef(new Set()).current

  const [enhancedDataKeys, setEnhancedDataKeys] = useState(
    dataKeys.map((dataKey, index) => ({
      ...dataKey,
      enabled: true,
      colors: getColors(dataKey.key, index),
    })),
  )

  const CustomTooltip = useCallback(
    ({ active, payload }) => {
      if (active && payload && payload.length && activePoint) {
        const measurementUnit = MEASUREMENT_UNITS_MAP[activePoint.dataKey]
        return (
          <Box id="custom-tooptip">
            <Box background="white" boxShadow="xl" rounded="2xl">
              <Flex direction="column" gap={2} p={4}>
                <Text fontSize="14px" color="text.secondary">
                  {activePoint.dataKey}
                </Text>
                <Flex alignItems="center">
                  <Text fontSize="20px" color="#484848" fontWeight={700}>
                    {addCommasToNumber(activePoint.payload[activePoint.dataKey])}
                  </Text>
                  <Text fontSize="16px" color="#484848" ml="2">
                    {measurementUnit}
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize="16px" color="#484848" ml="2">
                    {period === 'day'
                      ? `${payload[0].payload.month} ${payload[0].payload.time}`
                      : payload[0].payload.time}
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Flex justifyContent="space-around">
              <Box
                mt="-1"
                height="0"
                width="0"
                borderLeft="15px solid transparent"
                borderRight="15px solid transparent"
                borderTop="20px solid white"
              />
            </Flex>
          </Box>
        )
      }

      return null
    },
    [activePoint],
  )

  const viewData = useMemo(
    () =>
      data.map((item, index) => {
        const values = enhancedDataKeys.reduce((acc, dataKey) => {
          acc[dataKey.displayValue] = item[dataKey.key] || 0
          return acc
        }, {})

        return {
          time:
            period === 'day' ? format(item.time, 'hh:mm a') : format(item.time, 'MMM d'),
          month: format(item.time, 'MMM d'),
          ...values,
        }
      }),
    [data, enhancedDataKeys, period],
  )

  const toolTipPosition = React.useMemo<TooltipProps<number, string>['position']>(() => {
    if (activePoint) {
      const OffsetY = 125

      const tooltipWidth = document.getElementById('custom-tooptip')?.offsetWidth

      return {
        x: activePoint.cx - tooltipWidth / 2,
        y: activePoint.cy - OffsetY,
      }
    }
    return undefined
  }, [activePoint])

  const findClosestItem = useCallback((set, cursorX, cursorY) => {
    let minDistance = Infinity
    let closestItem = null

    set.forEach((item) => {
      const distance = Math.sqrt(
        Math.pow(item.cx - cursorX, 2) + Math.pow(item.cy - cursorY, 2),
      )
      if (distance < minDistance) {
        minDistance = distance
        closestItem = item
      }
    })

    return closestItem
  }, [])

  const addItemIfNotExists = useCallback((set, newItem) => {
    const exists = [...set].some((item) => item.key === newItem.key)

    if (!exists) {
      set.add(newItem)
    }
  }, [])

  const renderActiveDot = (props) => {
    addItemIfNotExists(activeDots, props)
    if (currentCoordinates) {
      const closest = findClosestItem(
        activeDots,
        currentCoordinates.x,
        currentCoordinates.y,
      )

      setActivePoint(closest)

      if (closest.dataKey === props.dataKey && props.cx && props.cy) {
        return (
          <svg
            x={closest.cx - 8.5} // Adjusted for new centering
            y={closest.cy - 8.5} // Adjusted for new centering
            width="17" // Increased width to accommodate larger stroke width
            height="17" // Increased height to accommodate larger stroke width
            viewBox="0 0 17 17" // Adjusted viewBox to new size
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="8.5" // Center of the new viewBox
              cy="8.5" // Center of the new viewBox
              r="6" // Keeping the radius same as before
              fill={props.stroke} // Using props.stroke for the fill color
              stroke={props.fill}
              strokeWidth="4"
            />
          </svg>
        )
      }
    }

    return null
  }

  const toggleLegendItem = useCallback(
    (index) => {
      const updatedItems = [...enhancedDataKeys]
      updatedItems[index].enabled = !updatedItems[index].enabled
      setEnhancedDataKeys(updatedItems)
    },
    [enhancedDataKeys],
  )

  const RenderLegend = useCallback(() => {
    return (
      <Flex gap="16px" ml="20px">
        {enhancedDataKeys.map(({ displayValue, colors }, index) => (
          <Flex
            key={index}
            alignItems="center"
            cursor="pointer"
            onClick={() => toggleLegendItem(index)}
          >
            <Box w="40px" h="16px" backgroundColor={colors.stroke} rounded="4px" />
            <Text fontSize="11px" fontWeight="400" color="#484848" ml="2">
              {displayValue}
            </Text>
          </Flex>
        ))}
      </Flex>
    )
  }, [enhancedDataKeys, toggleLegendItem])

  // Highlight and Zoom

  return (
    <Box>
      <Text pl="10px" pb="16px" fontSize="14px" fontWeight="700" color="text.secondary">
        {title}
      </Text>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          onMouseMove={(e) => {
            setCurrentCoordinates(e.activeCoordinate)
          }}
          data={viewData}
          margin={{ top: 0, left: 0, right: 25, bottom: 0 }}
        >
          <defs>
            {enhancedDataKeys.map(({ displayValue, key, enabled, colors }, index) => {
              if (enabled) {
                return (
                  <linearGradient
                    key={index}
                    id={`color${key}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    {Object.keys(colors.stops).map((key, index) => (
                      <stop
                        key={`${index}-${key}`}
                        offset={`${key}%`}
                        stopColor={`${colors.stops[key]}`}
                        stopOpacity={1}
                      />
                    ))}
                  </linearGradient>
                )
              }

              return null
            })}
          </defs>

          <defs>
            <linearGradient id="colorUvtest" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor="#FF715080" stopOpacity={1} />
              <stop offset="100%" stopColor="#1DABFC4D" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis
            dataKey="time"
            tickLine={false}
            interval={1}
            stroke="#ccc"
            style={{ fill: 'gray', fontSize: '11px', color: textSecondary }}
          />
          <YAxis
            tickLine={false}
            width={40}
            stroke="#ccc"
            style={{ fill: 'gray', fontSize: '11px', color: textSecondary }}
            domain={[0, (dataMax) => Math.ceil(dataMax + dataMax / 10)]}
          />
          <Tooltip
            content={CustomTooltip}
            position={toolTipPosition}
            allowEscapeViewBox={{ x: true, y: true }}
          />
          {dataKeys.length > 1 ? <Legend content={RenderLegend} /> : null}

          {enhancedDataKeys.map((dataKey, index) => {
            if (dataKey.enabled) {
              return (
                //  <Area
                //   key={`area-${dataKey.key}-${index}`}
                //   type="monotone"
                //   isAnimationActive={false}
                //   stroke="transparent"
                //   dataKey={dataKey.displayValue}
                //   activeDot={null}
                //   fillOpacity={
                //     !activePoint || activePoint.dataKey === dataKey.displayValue
                //       ? 1
                //       : 0.3
                //   }
                //   fill={`url(#color${dataKey.key})`}
                // />
                <Line
                  key={`line-${dataKey.key}-${index}`}
                  type="monotone"
                  dataKey={dataKey.displayValue}
                  stroke={dataKey.colors.stroke}
                  strokeWidth="4"
                  dot={<CustomDot />}
                  activeDot={renderActiveDot}
                />
              )
            }

            return null
          })}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}
